import { ButtonProps, Button as MantineButton } from '@mantine/core';
import { ReactNode } from 'react';
import classes from './Button.module.css';

interface Props extends ButtonProps {
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit' | 'disabled';
  children: ReactNode;
}

const btnColor: Record<string, string> = {
  button: '#000000',
  disabled: '#E8E9F1',
  reset: '#8F9098',
  submit: '#000000',
};

export const Button = ({
  onClick,
  children,
  type = 'button',
  ...rest
}: Props) => (
  <MantineButton
    {...rest}
    //@ts-ignore
    type={type}
    className={`${classes.button} ${classes[type]}`}
    color={btnColor[type]}
    onClick={onClick}
    w="auto"
  >
    {children}
  </MantineButton>
);
