import { Select } from '@mantine/core';
import { useMemo } from 'react';
import countryCodes from 'country-codes-list';
import classes from './CountryCode.module.css';

interface Props {
  label: string;
  value: string;
  defaultValue?: string;
  onChange: (value: string | null) => void;
}

export const CountryCode = (props: Props) => {
  const phoneCountryCodes = useMemo(
    () =>
      Object.entries(
        countryCodes.customList(
          // @ts-ignore
          'countryCallingCode',
          '{flag} +{countryCallingCode}',
        ),
      ).map(([value, label]) => ({ value, label })),
    [],
  );

  return (
    <Select
      classNames={{ input: classes.select }}
      {...props}
      data={phoneCountryCodes}
      searchable
      w={110}
    />
  );
};
