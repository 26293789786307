import { Flex, Text, rem } from '@mantine/core';
import { ReactNode } from 'react';

interface Props {
  title: string;
  actions?: ReactNode;
}

export const PageHeader = ({ title, actions }: Props) => (
  <Flex mb={rem(20)} justify="space-between" align="center">
    <Text fw={600} fz={30}>
      {title}
    </Text>
    {actions}
  </Flex>
);
