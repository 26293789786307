'use client';

import { ReactNode, createContext } from 'react';
import { Dictionary } from '@/interfaces/dictionary';

interface Props {
  locale: string;
  dictionary: Dictionary;
  children: ReactNode;
}

interface DictionaryContextType {
  locale: string;
  dictionary: Dictionary;
}

export const DictionaryContext = createContext<DictionaryContextType>({
  locale: 'en',
  dictionary: {},
});

export const Translations = ({ locale, dictionary, children }: Props) => (
  <DictionaryContext.Provider value={{ locale, dictionary }}>
    {children}
  </DictionaryContext.Provider>
);
