import { ReactNode } from 'react';
import NextLink, { LinkProps } from 'next/link';
import cx from 'classnames';
import classes from './Link.module.css';

interface Props extends LinkProps {
  thin?: boolean;
  children: ReactNode;
}

export const Link = ({ href, children, thin, ...others }: Props) => (
  <NextLink
    className={cx(classes.link, { [classes.thin]: thin })}
    href={href}
    {...others}
  >
    {children}
  </NextLink>
);
