import { TextInput as MantineTextInput, TextInputProps } from '@mantine/core';
import classes from './TextInput.module.css';

export const TextInput = (props: TextInputProps) => (
  <MantineTextInput
    variant="filled"
    {...props}
    classNames={{ label: classes.label }}
  />
);
