'use client';

import { TextInputProps } from '@mantine/core';
import { useMemo, useState } from 'react';
import { Eye, EyeSlash } from '@phosphor-icons/react/dist/ssr';
import { TextInput } from '@/components';
import classes from './PasswordField.module.css';

export const PasswordField = (props: TextInputProps) => {
  const [type, setType] = useState<'password' | 'text'>('password');

  const eyeIcon = useMemo(
    () => (type === 'password' ? <EyeSlash /> : <Eye />),
    [type],
  );

  const handleEyeToggle = () => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };

  return (
    <TextInput
      className={classes.password}
      {...props}
      type={type}
      rightSection={eyeIcon}
      rightSectionProps={{
        onClick: handleEyeToggle,
        className: classes.icon,
      }}
    />
  );
};
