'use client';

import { useState } from 'react';
import {
  Badge,
  CloseButton,
  Flex,
  Group,
  Text,
  TextInputProps,
} from '@mantine/core';
import { TextInput } from '../TextInput';

interface Props extends TextInputProps {
  initialTags?: string[];
  onCreate: (items: string[]) => void;
}

export const TagInput = ({ initialTags = [], onCreate, ...other }: Props) => {
  const [value, setValue] = useState<string>('');
  const [tags, setTags] = useState<string[]>(initialTags);

  const handleRemoveTag = (tag: string) => {
    setTags([...tags.filter((t) => t !== tag)]);
  };

  const handleChange = (event: { target: { value: string } }) => {
    setValue(event.target.value);
  };

  const handleTagCreate = (event: {
    key: string;
    code: string;
    preventDefault: () => void;
  }) => {
    if (event.key === 'Enter' && value.length > 0) {
      event.preventDefault();
      const newTags = [...tags, value];
      setTags(newTags);
      setValue('');
      onCreate(newTags);
    }
  };

  return (
    <div>
      <TextInput
        {...other}
        value={value}
        onChange={handleChange}
        onKeyDown={handleTagCreate}
      />
      <Group mt="sm">
        {tags.map((tag) => (
          <Badge radius={4} color="#E8E9F1">
            <Flex align="center" gap={5}>
              <Text fz={10} c="#1F2024">
                {tag}
              </Text>
              <CloseButton size={13} onClick={() => handleRemoveTag(tag)} />
            </Flex>
          </Badge>
        ))}
      </Group>
    </div>
  );
};
