'use client';

import {
  Combobox,
  Flex,
  Input,
  InputBase,
  InputLabel,
  Text,
  useCombobox,
} from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react/dist/ssr';
import { useMemo, useState } from 'react';

export interface MultiLevelSelectGroupOption {
  value: string;
  label: string;
}

export interface MultiLevelSelectGroup {
  id: string;
  group: string;
  options: MultiLevelSelectGroupOption[];
}

interface MultiLevelSelectValue {
  group?: string;
  option: string;
}

interface Props {
  label: string;
  placeholder?: string;
  options: MultiLevelSelectGroup[];
  value?: MultiLevelSelectValue;
  onChange: (group: string, option: string) => void;
}

export const MultiLevelSelect = ({
  label,
  placeholder,
  options,
  value,
  onChange,
}: Props) => {
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(
    value?.group,
  );
  const combobox = useCombobox({
    onDropdownClose: () => {
      setSelectedGroup(undefined);
      combobox.resetSelectedOption();
    },
  });
  const [currentValue, setCurrentValue] = useState<
    MultiLevelSelectValue | undefined
  >(value);

  const groups = useMemo(
    () =>
      options.map((item) => (
        <Combobox.Option
          value={item.group}
          key={item.group}
          onClick={() => setSelectedGroup(item.group)}
        >
          <Flex align="center" justify="space-between">
            <Text>{item.group}</Text>
            <CaretRight />
          </Flex>
        </Combobox.Option>
      )),
    [options],
  );

  const groupOptions = useMemo(() => {
    if (!selectedGroup) return [];
    const group = options.find((option) => option.group === selectedGroup);
    return group
      ? group.options.map((op) => (
          <Combobox.Option
            value={op.value}
            key={op.value}
            onClick={() => onChange(selectedGroup, op.value)}
          >
            {op.label}
          </Combobox.Option>
        ))
      : [];
  }, [options, selectedGroup]);

  const getOptionLabel = () => {
    if (!currentValue?.group && currentValue?.option) {
      let optionLabel = '';

      options.every((op) => {
        const selectedOption = op.options.find(
          (opt) => opt.label === currentValue.option,
        );

        if (selectedOption) {
          optionLabel = selectedOption.label;
        }

        return !selectedOption;
      });

      return optionLabel;
    }

    const group = options.find(
      (option) => option.group === currentValue?.group,
    );
    const option = group?.options.find(
      (op) => op.value === currentValue?.option,
    );

    return option?.label;
  };

  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
          if (selectedGroup) {
            setCurrentValue({ group: selectedGroup, option: val });
            setSelectedGroup(undefined);
            combobox.closeDropdown();
          }
        }}
      >
        <Combobox.Target>
          <InputBase
            component="button"
            type="button"
            variant="filled"
            pointer
            rightSection={<Combobox.Chevron />}
            onClick={() => combobox.toggleDropdown()}
            rightSectionPointerEvents="none"
          >
            {getOptionLabel() || (
              <Input.Placeholder>{placeholder}</Input.Placeholder>
            )}
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            {!selectedGroup && groups}
            {selectedGroup && groupOptions}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
};
